/* eslint-disable import/prefer-default-export */

export const storeConfig = `
    {
        storeConfig {
            amoeba
            weltpixel_infinite_scroll_ajax_catalog_config
            secure_base_media_url
            secure_base_static_url
            customer_password_minimum_password_length
            customer_password_required_character_classes_number
            base_media_url
            base_static_url
            base_url
            base_currency_code
            capillary
            capillary_otp
            fazpass_otp
            fazpass_otp_duration
            code
            catalog_search_engine
            copyright
            catalog_default_sort_by
            category_url_suffix
            default_title
            default_keywords
            default_description
            default_display_currency_code
            header_logo_src
            head_shortcut_icon
            icube_pinlocation_gmap_key
            icube_pinlocation_geocoding_key
            logo_alt
            logo_width
            logo_height
            store_name
            welcome
            timezone
            title_prefix
            title_suffix
            title_separator
            website_id
            weight_unit
            oauth_access_token_lifetime_customer
            payments_configuration
            shipments_configuration
            snap_client_key
            snap_is_production
            aw_blog_general_enabled
            pickup_store
        }
    }
`;

export const getCmsList = `
{
  storeConfig {
    cms_page  
  }
}
`;
